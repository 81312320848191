<template>
    <div class="index-content">
        <div class="society">
            <p class="society-title">学会介绍</p>
            <img class="society-img" src="@/assets/image/survey.png"/>
            <p class="society-p">书画艺术学会和书画艺术学院，一直致力于书画的普及提高及宣传工作。定向定时开展书画研讨、交流、展出活动。如“深港书画交流展”、“京港深琼书画展”“旅美画家左夫”全国书协学术委员会主任丛文俊、马来西亚画家钟正山名家讲学等。2006、2007、2008连续三年成功承办了由文化局主办的“文化进社区书画作品展”、2007年还圆满完成了第八届鹏城金秋的美术和设计类作品展，受到广泛好评。特别是几届“深港书画交流展”的成功举办,使学院、学会成为深港两地的一个文化纽带。</p>
            <div class="society-pop">
                <p class="society-pop-title">深圳市书画艺术学会</p>
                <div class="society-pop-line"></div>
                <p style="text-indent: 2em;">深圳市书画艺术学会成立于1995年，以举办学术研究、交流活动为主，旨在为深圳书画名家和艺术爱好者提供一个交流和展示的平台，以深圳为中心，组织国内名家的交流，同时凭借毗邻深港的优势，为深、港、澳的艺术沟通搭建桥梁和纽带。现有著名艺术家近百人，会员三百余人。</p>
            </div>
        </div>
    </div>
</template>
<script>
import footers from '@/components/footers'
export default {
    name: 'introduce',
    data(){
        return{
            
        }
    },
    created() {
        
    },
    methods:{
        getList(){
            
        }
    }
}
</script>
<style lang="scss" scoped>
    .index-content{
        width: 100%;
        background: #F6F6EA;
        display: flex;
        justify-content: center;
    }
    @media screen and (max-width: 1200px){
        .society{
            width: 100%;
        }
    }
    .society{
        width: 1200px;
        padding: 60px 40px;
        background: #ffffff;
        position: relative;
    }
    .society-title{
        text-align: center;
        font-weight: bold;
        color: #272727;
        font-size: 30px;
        padding-bottom: 40px;
    }
    .society-img{
        width: 790px;
        height: 429px;
    }
    .society-p{
        padding-top: 35px;
        color: #46382E;
        font-size: 16px;
        text-indent: 2em;
    }
    // 弹框
    .society-pop{
        width: 484px;
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
        padding: 40px;
        background: #ffffff;
        position: absolute;
        top: 25%;
        right: 40px;
    }
    .society-pop-title{
        color: #46382E;
        font-size: 24px;
        padding-bottom: 13px;
    }
    .society-pop-line{
        margin-bottom: 20px;
        width: 49px;
        height: 5px;
        background: #26716C;
    }
</style>